@font-face {
  font-family: "Chewy";
  font-style: normal;
  font-weight: 400;
  src: url("./content/chewy-font/chewy-v18-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./content/chewy-font/chewy-v18-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./content/chewy-font/chewy-v18-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./content/chewy-font/chewy-v18-latin-regular.woff") format("woff"),
    /* Modern Browsers */
      url("./content/chewy-font/chewy-v18-latin-regular.ttf") format("truetype"),
    /* Safari, Android, iOS */
      url("./content/chewy-font/chewy-v18-latin-regular.svg#Chewy")
      format("svg"); /* Legacy iOS */
}

body {
  --body-background-color: rgba(190, 252, 255, 1);
  --body-text-color: #000;

  --head-background-color: #3fdcff;

  --head-text-color: #000;
  --head-text-copyright-color: #444;

  --border: 2px solid black;

  font-family: Chewy, Verdana, Geneva, Tahoma, sans-serif;
  font-size: 16px;
  background-color: var(--body-background-color);

  width: 100%;
  /* max-width: 2000px; */
}

main {
  min-height: 720px;
}
header,
footer {
  background-color: var(--head-background-color);
  color: var(--head-text-color);
}

.carousel-indicators [data-bs-target] {
  width: 10px;
  height: 10px;
  background-color: var(--head-text-color);
  border-radius: 50%;
}

#logo {
  width: 30vw;
  max-width: 205px;
  display: inline-block;
}

#header-1-row {
  width: 100%;
}

header ul li,
footer ul li {
  font-size: 1.3rem;
}

.nav-link {
  color: var(--head-text-color);
}

/* MAIN SECTION STYLE */

main {
  color: var(--body-text-color);
  text-align: center;
  width: 80%;
  margin: 0 auto;
}

h1,
#game-title {
  font-size: 2rem;
  margin: 2rem;

  /* color: black;
  -webkit-text-fill-color: white; 
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black; */
}

/* Homepage style */

#homepage #games {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

#homepage .game-block {
  margin-bottom: 1.8rem;
  position: relative;
  border-radius: 15px;
  height: 360px;
  overflow: hidden;
}

#homepage .game-block img {
  height: 100%;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

#homepage .middle {
  transition: 0.5s;
  opacity: 0;
  position: absolute;
  bottom: 0;
  left: 50%;
  color: var(--body-background-color);
}

#homepage .middle .text {
  transform: translate(-50%, 30%);
  font-size: 1.6rem;
}

#homepage .game-block:hover .image {
  filter: brightness(75%);
  transform: scale(1.1);
}

#homepage .game-block:hover .middle {
  opacity: 1;
  transform: translate(0%, -40%);
}

/* Single game view style */

#game-view {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: repeat(auto-fit, 350px);
  gap: 10px;
}
#game-view #game-title {
  grid-row: 1;
  grid-column: 1 / 3;
  text-align: left;
  font-size: 2rem;
}

#game-view #game-description {
  margin-left: 2rem;
  grid-row: 2;
  grid-column: 1;
  text-align: left;
}
#game-view #game-links {
  grid-row: 2;
  grid-column: 2;
  display: block;
  margin: 10px;
}

#game-links * {
  margin: 5px;
}

#game-view #game-screenshots {
  grid-row: 3;
  grid-column: 1 /3;
}

@media (max-width: 800px) {
  main #game-view {
    display: block;
  }

  #game-view #game-title,
  #game-description {
    text-align: center;
  }
}

#game-view .img-responsive {
  width: 300px;
  max-width: 100%;
  margin: 10px;
}

/* Contacts view style */

#contacts .contactsBlock {
  background-color: var(--head-background-color);
  border-radius: 15px;
  text-align: center;
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
  align-items: baseline;
}

#contacts .contactsContent {
  display: block;
  text-align: left;
  line-height: 3rem;
  margin: 3rem;
  text-align: left;
  width: fit-content;
}
#contacts .link-item * {
  color: var(--head-text-color);
  text-decoration: none;
}

#contacts svg {
  height: 2rem;
  fill: var(--head-text-color);
}

@media (max-width: 420px) {
  #contacts .link-item * {
    display: block;
    text-align: center;
    margin: auto;
  }
}

/* Privacy policy view style */

#privacy-policy h1 {
  text-align: center;
}

#privacy-policy {
  text-align: left;
}

/* About us view style */
#about-us h1 {
  text-align: center;
}

#about-us {
  text-align: left;
}

/* Error 404 view style */

#error .er-info {
  color: var(--body-text-color);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
}

#error h1 {
  font-size: 7vw;
}

#error p {
  font-size: 3.5vw;
}

#error #er-image img {
  min-width: 200px;
  max-width: 30vw;
}

/* Footer style */
footer {
  width: 100%;
  padding: 10px 7vw;
  display: grid;
  grid-template-columns: 1fr 3fr;
}

.footer-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

footer ul {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  column-gap: 1rem;
  padding-left: 0px;
}

footer ul li {
  display: inline;
  font-size: 1rem;
}

footer .copyright {
  font-size: 0.7rem;
  color: var(--head-text-copyright-color);
}

@media (max-width: 520px) {
  footer {
    display: block;
    text-align: center;
  }
  .footer-info {
    align-items: center;
  }
  footer ul {
    justify-content: center;
  }
}
